/* eslint-disable no-plusplus */

import {
  LIST_INVALID_BUSINESS_WEBSITE,
  LIST_SHORTEN_URL,
} from '@/constants'

export const validatorPositive = value => value >= 0

export const validatorGreaterThanZero = value => value > 0

export const validatorGreaterThanOne = value => value >= 1

export const validatorGreaterThanTen = value => value >= 10

export const validatorEqualTwenty = value => value.length === 20

export const validatorTwoDigits = value => {
  if (value.toString().includes('.')) {
    return value.toString().split('.')[1].length < 3
  }

  return true
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.{6,})/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  // /^(?:https?:\/\/(?:www\.)?|www\.)[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/\S*)?$/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorUrlWithProtocolsValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^((http(s)?):\/\/)/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorChecked = val => val === true

export const validateTopUp = (val, { min }) => val <= 100000 && val >= min

export const validateMinAmount = (val, { min }) => val >= min

export const validateTip = (val, { min }) => val <= 100000 && val >= min

export const validateRefundLocalBank = val => val <= 100000 && val > 0

export const validateAutoTopUpAmount = (val, { min, max }) => val <= max && val >= min

export const validateTopUpDev = val => val <= 100000 && val >= 1
export const validateIntegerNumber = val => {
  const string = val.toString()
  return !string.includes('.')
}

export const validateVNDTopUp = val => val <= 2320000000 && val >= 4640000

export const validateAmountNumber = val => (/^[0-9]*$/.test(val))

export const validatorFaceBookUrl = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^https:\/\/www.facebook.com/m
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorCodeLength = val => val.length === 4

export const validatorVerifyCode = val => val.length === 6

export const validatorPostalCodeLength = value => value.length <= 10

export const validatorTelegramLength = val => val.length <= 100

export const validatorExcludeUrl = url => {
  if (!url) return false
  // eslint-disable-next-line no-useless-concat
  const pattern = new RegExp('^(https?:\\/\\/)' + '([a-zA-Z0-9][a-zA-Z0-9-]*)', 'i')
  return !pattern.test(url)
}

export const validatorProductLink = url => {
  if (url === undefined || url === null || url.length === 0) {
    return true
  }

  return !LIST_SHORTEN_URL.some(item => url.toLowerCase().includes(item))
}

export const validatorPhoneNumberWhatApp = phone => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{1,3}[-\s\.]?[0-9]{0,3}[-\s\.]?[0-9]{0,41}$/im

  return re.test(phone)
}

export const validPixelScript = script => /ttq.load\('(.*?)'\)/.test(script)

export const validatorBusinessWebsite = url => !LIST_INVALID_BUSINESS_WEBSITE.some(invalidHostName => url.includes(invalidHostName))
